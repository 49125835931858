.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

table {
  border-spacing: 0;
  border: 1px solid black;
  margin-left: auto;
  margin-right: auto;
}

th,
td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}
td:last-child {
  border-right: 0;
}
input {
  font-size: 1rem;
  padding: 0;
  margin: 0;
  border: 0;
}
.cellinput {
  font-size: 1rem;
  background-color: bisque;
  padding: 0;
  margin: 0;
  border: 0;
}

tr:last-child {
  td : {
    border-bottom: 0;
  }
}

.center {
  margin: auto;
  padding: 10px;
}

.addButton {
  margin: 5px;
}
