.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Nav {
  background-color: lightgray;
}

.emptyDropDiv {
  background-color: aqua;
  text-align: center;
}

.rTableRow {
  width: 100%;
  height: '15px';
}

.row {
  margin-right: 2px;
  margin-left: 2px;
}

#testRow {
  margin-right: 3px;
  margin-left: 3px;
}

.MuiChip-colorPrimary {
  color: aqua;
}
